import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'

const StatisticSlideItem2 = ({ title, subtitle, images }) => {
	return (
		<>
			<div className='apl-statisticslide--header'>
				<h2>{title}</h2>
				<h3 style={{ margin: '0 auto 66px auto' }}>{subtitle}</h3>
			</div>
			<div className='apl-statisticslide--item2'>
				<div className='columns is-multiline'>
					<div className='column is-3 is-hidden-widescreen'>
						<div className='apl-statisticslide--item2--card apl-statisticslide--item2--card--full'>
							<h4>Pendapatan Meningkat</h4>
							<div className='columns is-multiline'>
								<div className='column is-12'>
									<div className='apl-statisticslide--item2--card--inside'>
										<h5>
											38<b>%</b>
										</h5>
										<h6>
											Rata-rata pendapatan dan
											kesejahteraan
										</h6>
									</div>
								</div>
								<div className='column is-12'>
									<div className='apl-statisticslide--item2--card--inside'>
										<h5>
											11<b>%</b>
										</h5>
										<h6>Peningkatan modal rata-rata</h6>
									</div>
								</div>
								<div className='column is-12'>
									<div className='apl-statisticslide--item2--card--inside'>
										<h5>
											1,5-3<b>x</b>
										</h5>
										<h6>
											Perputaran persediaan lebih cepat
										</h6>
									</div>
								</div>
							</div>
							<GatsbyImage
								className='milestone-image-01'
								image={getImage(images[0])}
								alt='wpg-illustration'
							/>
						</div>
					</div>

					<div className='column is-6 is-hidden-widescreen'>
						<div className='apl-statisticslide--item2--card apl-statisticslide--item2--card--quarter'>
							<h4>Pemberdayaan yang lebih baik</h4>
							<div className='columns is-multiline'>
								<div className='column is-12'>
									<div className='apl-statisticslide--item2--card--inside apl-statisticslide--item2--card--direction apl-statisticslide--item2--card--start'>
										<div className='apl-statisticslide--item2--card--inside--offset'>
											<div>
												<h6 style={{ width: '100px' }}>
													hampir
												</h6>
												<h5 style={{ width: '100px' }}>
													50<b>%</b>
												</h5>
											</div>
											<h6>
												Juragan adalah perempuan, dan
												mereka menyumbang 75% pendapatan
												keluarga dari warung
											</h6>
										</div>
										<GatsbyImage
											className='milestone-image-02'
											image={getImage(images[1])}
											alt='wpg-illustration'
										/>
									</div>
								</div>
							</div>
						</div>
						<div className='apl-statisticslide--item2--card apl-statisticslide--item2--card--semifull'>
							<h4>Efisiensi Waktu yang Lebih Baik</h4>
							<GatsbyImage
								className='milestone-image-04'
								image={getImage(images[3])}
								alt='wpg-illustration'
							/>
							<div className='columns is-multiline'>
								<div className='column is-12'>
									<div className='apl-statisticslide--item2--card--inside apl-statisticslide--item2--card--direction'>
										<h5>
											87<b>%</b>
										</h5>
										<h6 className='apl-statisticslide--item2--card--fit'>
											Tingkat kepuasan efektivitas
											keseluruhan dan peningkatan waktu
											kualitas keluarga
										</h6>
									</div>
								</div>
								<div className='column is-6'>
									<div className='apl-statisticslide--item2--card--inside'>
										<h5>3-5</h5>
										<strong>Jam</strong>
										<h6>
											Waktu yang dihemat oleh layanan
											pengiriman <br /> <br />
										</h6>
									</div>
								</div>
								<div className='column is-6'>
									<div className='apl-statisticslide--item2--card--inside'>
										<h5>2.5</h5>
										<strong>Jam</strong>
										<h6 className='apl-statisticslide--item2--card--fitfull'>
											Maks. waktu pengiriman grosir
											melalui Grosir Pintar
										</h6>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='column is-3 is-hidden-widescreen'>
						<div className='apl-statisticslide--item2--card apl-statisticslide--item2--card--full'>
							<h4>Efisiensi Biaya</h4>
							<div className='columns is-multiline'>
								<div className='column is-12'>
									<div className='apl-statisticslide--item2--card--inside'>
										<h6>
											Hemat hingga Rp 600.000 tiap bulan
											untuk biaya transportasi, biaya
											parkir dan biaya pengiriman.
										</h6>
									</div>
								</div>
								<div className='column is-12'>
									<div className='apl-statisticslide--item2--card--inside'>
										<h5>
											20<b>%</b>
										</h5>
										<h6>
											Harga yang lebih rendah dan
											kompetitif karena rantai distribusi
											yang lebih pendek dan kepatuhan
											terhadap standar harga penjualan
											eceran
										</h6>
									</div>
								</div>
							</div>
							<GatsbyImage
								className='milestone-image-03'
								image={getImage(images[2])}
								alt='wpg-illustration'
							/>
						</div>
					</div>

					<div className='column is-12 is-hidden-until-widescreen'>
						<div className='apl-statisticslide--item2--card'>
							<div className='apl-statisticslide--item2--card--inside'>
								<h5>Pendapatan Meningkat</h5>
								<h6>
									38 % Rata-rata pendapatan dan kesejahteraan
								</h6>
								<GatsbyImage
									className='milestone-image-01'
									image={getImage(images[0])}
									alt='wpg-illustration'
								/>
							</div>
							<div className='apl-statisticslide--item2--card--inside'>
								<h5>Efisiensi Waktu yang Lebih Baik</h5>
								<h6>
									Tingkat kepuasan efektivitas keseluruhan dan
									peningkatan waktu kualitas keluarga
								</h6>
								<GatsbyImage
									className='milestone-image-04'
									image={getImage(images[3])}
									alt='wpg-illustration'
								/>
							</div>
							<div className='apl-statisticslide--item2--card--inside'>
								<h5>Efisiensi Biaya</h5>
								<h6>
									Hemat hingga Rp 600.000 tiap bulan untuk
									biaya transportasi, biaya parkir dan biaya
									pengiriman.
								</h6>
								<GatsbyImage
									className='milestone-image-03'
									image={getImage(images[2])}
									alt='wpg-illustration'
								/>
							</div>
							<div className='apl-statisticslide--item2--card--inside'>
								<h5>Pemberdayaan yang lebih baik</h5>
								<h6>
									50% Juragan adalah perempuan, dan mereka
									menyumbang 75% pendapatan keluarga dari
									warung
								</h6>
								<GatsbyImage
									className='milestone-image-02'
									image={getImage(images[1])}
									alt='wpg-illustration'
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

StatisticSlideItem2.displayName = 'StatisticSlideItem2'

StatisticSlideItem2.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	images: PropTypes.array,
}

StatisticSlideItem2.defaultProps = {
	title: null,
	subtitle: null,
	images: [],
}

export default StatisticSlideItem2
